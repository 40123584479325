<script setup lang="ts">
import _ from "lodash";
import {computed, inject, onMounted, ref, watch, unref, toRefs} from "vue";
import {storeToRefs} from "pinia";
import {useI18n} from "vue-i18n";
import DevHelper from "@/components/Global/DevHelper.vue";
import OperationsActionsMenu from "@/components/Scheduling/Operations/OperationsActionsMenu.vue";
import {useParameterStore} from "@/stores/parameterStore";
import {useMainStore} from "@/stores/mainStore";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useIsArchivedSimulation} from "@/composables/useIsArchivedSimulation";
import useComputeQuantityUnit from "@/composables/useComputeQuantityUnit";
import {calculateOfDelay, SchedulingMessage} from "@oplit/shared-module";
import {
  computedDisplayFn,
  getArrowsState,
  getColorCategoryClass,
  getFullOperation,
  onClickOutsideSchedulingOperation,
  operationComputedStagnation,
} from "@/tscript/utils/schedulingUtils";
import {
  CSS_OPERATION_CARD_CLASS,
  CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
  CSS_OPERATION_CARD_SELECTED_CLASS,
  OF_STATUS,
} from "@/config/constants";
import {
  OpenOFSidebarFunction,
  SchedulingOperation,
  OperationsActionsMenuSelectedOps,
} from "@/interfaces";
import {useOperationStatus} from "@/composables/scheduling/useOperationStatus";

type OperationsActionsMenuProps = InstanceType<
  typeof OperationsActionsMenu
>["$props"];

interface SchedulingOperationWrapperProps {
  operation: SchedulingOperation;
  // for the "wip" view : whether or not this operation reaches the capa limit
  isOngoingLimit?: boolean;
  // whether or not some OPs are done in the currently selected operations
  hasDoneOps?: boolean;
  forcedHeight?: number;
  // whether or not this operation is within an OperationsCardsGroup
  isOpFromGroup?: boolean;
  // this array contains arbitrary strings that will hide specific parts of this component given the string
  hiddenInfos?: string[];
  // this prop is used to calculate the stagnation, which is the difference between this operation's day date
  // and the date of the last operation of the same OF with the status "done"
  showStagnation?: boolean;
  // controls the visibility of the expand & new message icons (opening the sidebar)
  compact?: boolean;
  isSyntheticView?: boolean;
  // index within the list of operationCards + 1
  order?: number;

  // actionsMenu props/utils
  actionsMenuDisplaySide?: "right" | "left";
  currentArray?: SchedulingOperation[];
  isPast?: boolean;
  // array representing the current index of the shift and the total count of shifts, used to display proper arrows in actions menu
  shiftPagination?: {current: number; max: number};
  doNotRenderLazy?: boolean;
  operationsActionsMenuProps?: OperationsActionsMenuProps;
  /**
   * used for the lazy-loading within a container other than the viewport of the page
   */
  intersectionObserverRoot?: Element;
  selectedOps?: OperationsActionsMenuSelectedOps;
}

const props = withDefaults(defineProps<SchedulingOperationWrapperProps>(), {
  operation: () => null as SchedulingOperation,
  hiddenInfos: () => [],
  operationsActionsMenuProps: () => ({} as OperationsActionsMenuProps),
  selectedOps: () => ({} as OperationsActionsMenuSelectedOps),
});

const emit = defineEmits<{
  (e: "operation-mounted", operationID: string);
  (
    e: "update-theoric-date",
    payload: {of_id: string; op_id: string; theoric_date: string},
  );
  (e: "move-operation", payload: {code: string; isOp: boolean});
  (e: "select-card", operation?: SchedulingOperation);
  (e: "change-operation-shift", direction: string);
}>();

const openOFSidebar = inject<OpenOFSidebarFunction>("openOFSidebar");

const {operationComputedQuantity} = useComputeQuantityUnit();
const {isArchivedSimulation} = useIsArchivedSimulation();
const {apiClient, userData, environment, teamIdBySector} = storeToRefs(
  useMainStore(),
);
const {parametersSchedulingDisplay, parametersAllSchedulingDisplaysByTeamId} =
  storeToRefs(useParameterStore());
const {
  schedulingCurrentColorCategory,
  selectedSimulation,
  canOperateOnOperationCards,
  schedulingTags,
  messagesByOfId,
} = storeToRefs(useSchedulingStore());
const {t} = useI18n();

const theoric_date = ref("");

const messages = computed<SchedulingMessage[]>(() => {
  const {of_id} = props.operation || {};
  if (!of_id) return [];
  return messagesByOfId.value[of_id] || [];
});

const fullOperation = computed(() => getFullOperation(props.operation));
const selectedOpsIDs = computed(() => Object.keys(props.selectedOps));
/**
 * returns a string representing the difference between this operation's day date
 * and the date of the last operation with the status "done" within the OF this operation's from
 * if no "done" operation is within this list, we return n/a
 */
const operationStagnation = computed(() => {
  if (!props.showStagnation) return;
  const stagnationValue = operationComputedStagnation(props.operation);
  if (stagnationValue === 0) return "< 1 jour";
  else if (!stagnationValue) return "n/a";
  return [stagnationValue, t("global.lower_day", stagnationValue)].join(" ");
});
const isSelected = computed(() => {
  if (!selectedOpsIDs.value.length) return false;
  return !!props.selectedOps[props.operation.op_id];
});
const {operation: operationRef} = toRefs(props);
const {displayedOperationStatus: computedStatus} =
  useOperationStatus(operationRef);
const computedDisplay = computed(() => {
  const sectorTeamId = teamIdBySector.value[fullOperation.value.secteur_id];
  const sector_scheduling_display =
    parametersAllSchedulingDisplaysByTeamId.value?.[sectorTeamId] ||
    parametersSchedulingDisplay.value;

  return computedDisplayFn(fullOperation.value, sector_scheduling_display, {
    theoric_date: theoric_date.value,
    client_name: userData.value.client_name,
    environment: environment.value,
  });
});
const operationDelay = computed(() => {
  const {
    erp_date,
    new_date,
    day_date,
    first_active_op_theoric_date,
    first_active_op_planned_date,
    is_day_date_modified,
    of_delta,
    all_ops_done,
    nb_ops_done,
    max_date_of,
  } = props.operation;

  return calculateOfDelay({
    initial_date: erp_date,
    new_date,
    day_date,
    theoric_date: max_date_of || theoric_date.value,
    first_active_op_theoric_date,
    first_active_op_planned_date,
    is_day_date_modified,
    real_date_delta: of_delta,
    all_ops_done,
    nb_ops_done,
  });
});
const isDisplayedActionsMenu = computed(
  (): boolean =>
    isSelected.value &&
    props.operation.op_id === selectedOpsIDs.value[0] &&
    !props.isOpFromGroup,
);
const colorCategoryClass = computed(() =>
  getColorCategoryClass(props.operation, schedulingCurrentColorCategory.value, {
    schedulingTags: schedulingTags.value,
  }),
);
const isEnded = computed(() => computedStatus.value?.text === OF_STATUS.DONE);
const operationQuantity = computed(() =>
  operationComputedQuantity(
    fullOperation.value,
    false,
    !props.hiddenInfos.includes("opQty"),
  ),
);
const wrapperClasses = computed(() => {
  return [
    {[CSS_OPERATION_CARD_SELECTED_CLASS]: isSelected.value},
    {"ended-card": isEnded.value},
    {"standard-card": !isEnded.value && !isSelected.value},
    {"pb-1": props.forcedHeight},
    CSS_OPERATION_CARD_CLASS,
    CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
  ];
});

const showSubheader = computed(() => {
  return (
    !props.hiddenInfos.includes("subheader") && unref(computedDisplay).subheader
  );
});

watch(
  () => props.operation,
  (newOperation, oldOperation) => {
    if (!newOperation?.op_id) return;
    if (
      newOperation.op_id === oldOperation?.op_id ||
      newOperation.import_id === oldOperation?.import_id
    )
      loadOfEndDate();
  },
  {immediate: true},
);

async function loadOfEndDate() {
  const {id: simulationId} = selectedSimulation.value || {};
  const {of_id, import_id, op_id, max_date_of} = props.operation || {};
  const {client_id} = userData.value || {};
  if (max_date_of) return (theoric_date.value = max_date_of); //the new parsing stores this info directly in daily_load
  if (!simulationId || !of_id || !client_id) return;
  if (theoric_date.value) return;
  //we only load theoric date, of_end_date is not used anymore
  const theoricDate = _.get(
    await apiClient.value.loadTheoricDate(import_id, of_id),
    [0, "day_date_max"],
    "",
  );
  emit("update-theoric-date", {of_id, op_id, theoric_date: theoricDate});
  theoric_date.value = theoricDate;
}
function showOperation(event: Event) {
  if (isSelected.value) event.stopPropagation();
  openOFSidebar(Object.assign(props.operation, {order: props.order}));
}
function onClickOutsideCard(event: Event): void {
  if (isArchivedSimulation.value || !isSelected.value) return;
  return onClickOutsideSchedulingOperation(event) ? emit("select-card") : null;
}
function moveOperation(action: string) {
  emit("move-operation", {code: action, isOp: true});
}
function selectCard(): void {
  // FIXME: this condition is also present in CalendarRow
  if (!canOperateOnOperationCards.value) return;
  emit("select-card", props.operation);
}
function changeOperationShift(direction: string) {
  emit("change-operation-shift", direction);
}

const slotProps = ref({
  header: unref(computedDisplay).header,
  subheader: showSubheader,
  colorCategoryClass,
  compact: props.compact,
  isSyntheticView: props.isSyntheticView,
  isOngoingLimit: props.isOngoingLimit,
  isSelected: isSelected,
  messages,
  operationDelay,
  operationQuantity,
  operationStagnation: operationStagnation,
  selectCard,
  showOperation,
});

const vLazyProps = computed(() =>
  props.intersectionObserverRoot
    ? {
        options: {
          threshold: 0.2,
          root: props.intersectionObserverRoot,
        },
        minHeight: 90,
      }
    : {},
);

onMounted(() => {
  emit("operation-mounted", props.operation.op_id);
});
</script>

<template>
  <div
    :id="operation.op_id"
    :class="wrapperClasses"
    class="scheduling-operation-wrapper"
    v-click-outside="onClickOutsideCard"
    :data-testid="`operation-card-${operation.op_id}`"
  >
    <DevHelper>{{ operation.firestore_id }}</DevHelper>

    <OperationsActionsMenu
      :display-side="actionsMenuDisplaySide"
      :show-actions-menu="isDisplayedActionsMenu"
      :arrows-state="getArrowsState(selectedOpsIDs, currentArray, isPast)"
      :shift-pagination="shiftPagination"
      :has-done-ops="hasDoneOps"
      :selected-ops="selectedOps"
      v-bind="operationsActionsMenuProps"
      @trigger-key-event="moveOperation"
      @change-operation-shift="changeOperationShift"
    />

    <template v-if="doNotRenderLazy">
      <slot v-bind="slotProps" />
    </template>
    <v-lazy v-else v-bind="vLazyProps">
      <slot v-bind="slotProps" />
    </v-lazy>
  </div>
</template>

<style scoped lang="scss">
.scheduling-operation-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px;
  padding-bottom: 0;
}
</style>
